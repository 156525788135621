<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="240"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/HTlogo.svg')"
          max-height="55px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <span><h2 class="app-title text--primary">
            賀桃投資控股</h2>
            <h4 class="pt-1"> {{ getDeptName(deptId) }} - {{ name }} <v-icon small　color="#9f9f9f">{{ icons.mdiHandWaveOutline }}</v-icon></h4> 
          </span>
        </v-slide-x-transition>
      </router-link>
    </div>
<div class="ps-6 pe-8 pt-3 pb-0" >
 
 <v-select 
      v-if="branchesName.length > 0" 
      :items="branchesName" 
      @input="updateDeptName(deptId)"
      item-value="id" 
      item-text="name"  
      v-model="deptId" 
      outlined  
      dense
  ></v-select>

</div>


    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5 pt-0"
    >

      <!-- <nav-menu-link
        title="應收帳款"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiCash"
      ></nav-menu-link> -->
      <nav-menu-link
        v-if="hasAccess(1)" 
        title="行程總覽"
        :to="{ name: 'calendar'}"
        :icon="icons.mdiCalendarBlank"
      ></nav-menu-link> 

       <nav-menu-section-title  v-if="hasAccess(2) || hasAccess(3) || hasAccess(4)"  title="業務功能" ></nav-menu-section-title> 

      <nav-menu-link
        v-if="hasAccess(2)" 
        title="客戶管理"
        :to="{ name: 'cust-list' }"
        :icon="icons.mdiAccountMultipleOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-if="hasAccess(3)" 
        title="個人業務"
        :to="{ name: 'user-business' }"
        :icon="icons.mdiBriefcaseSearchOutline "
      ></nav-menu-link>

      <nav-menu-link
        v-if="hasAccess(4)" 
        title="全站業務"
        :to="{ name: 'dept-business' }"
        :icon="icons.mdiStoreSearchOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-if="hasAccess(6)" 
        title="全區業務"
        :to="{ name: 'area-business' }"
        :icon="icons.mdiLabelVariantOutline"
      ></nav-menu-link>

      <nav-menu-link

        title="業務營業統計"
        :to="{ name: 'sales-business-dashboard' }"
        :icon="icons.mdiChartBoxOutline"
      ></nav-menu-link>
            
   
       <!-- <nav-menu-link
        title="業務數據"
        :to="{ name: 'business'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>   -->
       <!-- <nav-menu-group
        title="測試區"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="登入"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="註冊"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group>  -->
      

      <!-- <nav-menu-link
        title="業務管理"
        :to="{ name: 'sales' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="客戶報價單"
        :to="{ name: 'rfq' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link> -->
      <!--
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
            <!-- <nav-menu-link
        title="個人設定"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>  -->
      <nav-menu-section-title  v-if="hasAccess(5)" title="其他功能"></nav-menu-section-title> 
      <nav-menu-link
        v-if="hasAccess(7)" 
        title="業務工具"
        :to="{ name: 'sales-tools'}"
        :icon="icons.mdiToolboxOutline"
      ></nav-menu-link> 
      <nav-menu-link
        v-if="hasAccess(5)" 
        title="體系資訊"
        :to="{ name: 'system-info'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> 
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCash,
  mdiAccountMultiple,
  mdiCalendarBlank,
  mdiBriefcaseSearchOutline,
  mdiAccountMultipleOutline,
  mdiStoreSearchOutline,
  mdiHandWaveOutline,
  mdiChartBoxOutline, 
  mdiToolboxOutline,
  mdiLabelVariantOutline,
} from '@mdi/js'
import { ref, onMounted  } from "@vue/composition-api";
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import axios from '@/utils/axios'
export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },

  setup() {

      const setDeptId = localStorage.getItem('setDeptId');
      const deptId = ref(Number(setDeptId)) //現在營站
      const name = localStorage.getItem('setName');
      const deptName = localStorage.getItem('setDeptName');
      // const roleId = localStorage.getItem('setRoleId');
      const branchesNameString = localStorage.getItem('setBranchesName');
      const branchesName = JSON.parse(branchesNameString); //營站選項
        

      const updateDeptName = (newDeptId) => {
          deptId.value = newDeptId;
          localStorage.setItem('setDeptId',deptId.value);
          window.location.reload();  
      };

      const getDeptName = (deptId) => {
      const branch = branchesName.find((item) => item.id === deptId);
      return branch ? branch.name : deptName;
    };

    const roleRight = ref([])

    onMounted(() => {
      axios.get('role_right').then((res) => {
        roleRight.value = res.data;
      });
    });

    const hasAccess = (pageId) => {
    const roleId = localStorage.getItem('setRoleId');
    const rolePages = roleRight.value[roleId]; 
    return rolePages && rolePages.includes(pageId);
  };
            
    return {
      branchesName, //選項
      deptId, //站號
      deptName, //站名
      name, //使用者
      updateDeptName, //更新
      getDeptName, //對應站名
 
      roleRight,
      hasAccess,
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCash,
        mdiAccountMultiple,
        mdiCalendarBlank,
        mdiAccountMultipleOutline,
        mdiBriefcaseSearchOutline,
        mdiStoreSearchOutline,
        mdiHandWaveOutline,
        mdiChartBoxOutline,
        mdiToolboxOutline,
        mdiLabelVariantOutline
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
