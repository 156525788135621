import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyALhqJl_1AZ2R2WXb88e8wMIv6ro2XzQIU",
  authDomain: "ht-water.firebaseapp.com",
  projectId: "ht-water",
  storageBucket: "ht-water.appspot.com",
  messagingSenderId: "508375603498",
  appId: "1:508375603498:web:8601f2ca39c950206c6653",
  measurementId: "G-K7E9VVY6V6"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app);

export {
  app,
  db
}