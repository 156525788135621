import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'calendar',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/pages/login', //登入頁面
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/calendar', //行程總攬
    name: 'calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,3,4,5] 
    }
  },
  {
    path: '/cust-list', //客戶管理
    name: 'cust-list',
    component: () => import('@/views/custlist/CustList.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,3,4] 
    }
  },
  {
    path: '/cust/:id', //檢視客戶資訊
    name: 'cust',
    component: () => import('@/views/sales/Sales.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,3,4] 
    }
  },
  {
    path: '/rfq/:id', //檢視報價單資訊
    name: 'rfq',
    component: () => import('@/views/rfq/Rfq.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,3,4] 
    }
  },
  {
    path: '/erfq/:id',  //對外電子報價單
    name: '/erfq',
    component: () => import('@/views/rfq/Erfq.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/user-business', //個人業務
    name: 'user-business',
    component: () => import('@/views/business/UserBusinessDashboard.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,3,4] 
    }
  },
   {
    path: '/dept-business', //全站業務
    name: 'dept-business',
    component: () => import('@/views/business/DeptBusinessDashboard.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,4,5] 
    }
  },
  {
    path: '/area-business', //拜訪通報
    name: 'area-business',
    component: () => import('@/views/business/AreaBusiness.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1,2,4,5] 
    }
  },
  {
    path: '/system-info', //體系資訊
    name: 'system-info',
    component: () => import('@/views/user/User.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: [1] 
    }
  },

  {
    path: '/sales-business-dashboard', //業務營業統計
    name: 'sales-business-dashboard',
    component: () => import('@/views/business/SalesBusinessDashboard.vue'),
  },

  {
    path: '/sales-tools',
    name: 'sales-tools',
    component: () => import('@/views/sales-tools/SalesTools.vue'),
  },

  
 
  // {
  //   path: '/bi',
  //   name: 'bi',
  //   component: () => import('@/views/bi/Bi.vue'),
  // },
  // {
  //   path: '/typography',
  //   name: 'typography',
  //   component: () => import('@/views/typography/Typography.vue'),
  // },

  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  // },
   


  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  // },
  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  // },
  // {
  //   path: '/pages/account-settings',
  //   name: 'pages-account-settings',
  //   component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  // },

  // {
  //   path: '/pages/register',
  //   name: 'pages-register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },


  {
    path: '/error-403', //403頁面
    name: 'error-403',
    component: () => import('@/views/Error403.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404', //404頁面
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// 權限路徑
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiredRoles = to.matched.reduce((acc, record) => {
    if (record.meta.requiredRoles) {
      acc.push(...record.meta.requiredRoles);
    }
    return acc;
  }, []);
  const userRoleId = parseInt(localStorage.getItem('setRoleId'));

  const regex = /^\/erfq/;
  if (regex.test(to.name)) {
    return next();
  }

  if (requiresAuth && !userRoleId) {
    next('/pages/login');
  } else if (requiresAuth && !requiredRoles.includes(userRoleId)) {
    next('/error-403');
  } else {
    next();
  }
});


export default router
