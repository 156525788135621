<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      class="mobile-fixed-bar" 
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <!-- <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field> -->

          <v-spacer></v-spacer>

          <!-- Right Content -->

          <!--變色 <theme-switcher></theme-switcher> -->
          <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
             {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            small
            class="ms-3"
            @click="triggerTour"
          >
            <v-icon>
             {{ icons.mdiCogOutline }}
            </v-icon>
          </v-btn>

          

          <v-btn
            icon
            small
            class="ms-3"
            @click="logout"
          >
            <v-icon>
             {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">

        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiLogoutVariant, mdiCogOutline } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import router from '@/router/index'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  setup(props, context) {
    const isDrawerOpen = ref(null)
    const logout = () => {
      localStorage.removeItem('HTwater.token');
      localStorage.removeItem('setName');
      localStorage.removeItem('setDeptId');
      localStorage.removeItem('setDeptName');
      localStorage.removeItem('setRoleId');
      localStorage.removeItem('setBranchesName');
      router.push({
            name: 'pages-login'
          })
    }

    const triggerTour = () => {
      context.root.$store.dispatch('triggerTour');
  };

    return {
      isDrawerOpen,
      logout,
      triggerTour,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiLogoutVariant,
        mdiCogOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1200px) {
  .mobile-fixed-bar {
    position: fixed !important;
  }
}
</style>
