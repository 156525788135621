import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}/api/`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('HTwater.token');
    const deptId = localStorage.getItem('setDeptId'); //取localStorage中deptId

    config.headers['Authorization'] = `Bearer ${token}`;
    config.params = { ...config.params, dept: deptId }; 
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;