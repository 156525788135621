import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tourStartedCount: 0,
  },
  mutations: {
    incrementTourStarted(state) {
      state.tourStartedCount += 1;
    },
  },
  actions: {
    triggerTour({ commit, state }) {
      commit('incrementTourStarted');
    },
  },
  modules: {

  },
})
