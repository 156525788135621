import '@/plugins/vue-composition-api'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { app } from './plugins/firebase'
import axios from './utils/axios'

//導引
import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)





Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('HTwater.token');

  if (!token && !to.meta?.requiresAuth) {
    return next()
  }

  if (token) {
    if (to.name === 'pages-login') {
      return next('/calendar')
    }
    axios.get('/verify')
      .then((res) => {
        const { status } = res.data
        if (status === 200) {
          return next()
        } else {
          localStorage.removeItem('HTwater.token');
          next('/pages/login')
        }
      })
  } else {
    next('/pages/login')
  }
});


new Vue({
  router,
  store,
  vuetify,
  app,
  render: h => h(App),
}).$mount('#app')
